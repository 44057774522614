import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MintingForm from './MintingForm';
import NFTDisplay from './NFTDisplay';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
      
        <Routes>
          <Route path="/create" element={<MintingForm />} />
          <Route path="/" element={<NFTDisplay />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
