import React, { useState, useEffect } from 'react';

function MintingForm() {
    const [campaignName, setCampaignName] = useState('');
    const [title, setTitle] = useState('');
    const [subject, setSubject] = useState('');
    const [url, setUrl] = useState('');
    const [total, setTotal] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [privateKey, setPrivateKey] = useState('');
    const [message, setMessage] = useState('');
    const [recipients, setRecipients] = useState([]);

    const sheetUrl = `https://docs.google.com/spreadsheets/d/1vw1CtJvFBtpdinQHO_-Lj0rloWw545zhfghHFTUIdpY/gviz/tq?tqx=out:csv&sheet=WalletAddresses-Grid%20view%20(1)`;

    useEffect(() => {
        const fetchRecipients = async () => {
            try {
                const response = await fetch(sheetUrl);
                const data = await response.text();
                const rows = data.split('\n').slice(1);
                const fetchedRecipients = rows.map(row => row.split(',')[0].trim());
                setRecipients(fetchedRecipients);
            } catch (error) {
                console.error('Error fetching recipients from Google Sheets:', error);
            }
        };

        fetchRecipients();
    }, [sheetUrl]);

    const handleImageUpload = (e) => {
        setImageFile(e.target.files[0]);
    };

    const handleMint = async () => {
        if (!recipients.length) {
            setMessage('No recipients available. Please check the Google Sheet.');
            return;
        }
    
        if (!imageFile) {
            setMessage('Please upload an image.');
            return;
        }
    
        if (!privateKey) {
            setMessage('Please enter your private key.');
            return;
        }
    
        try {
            setMessage('Uploading image to IPFS...');
    
            const formData = new FormData();
            formData.append('file', imageFile);
    
            // Updated URL to the new server
            const imageResponse = await fetch('https://totalconversion.ai/upload', {
                method: 'POST',
                body: formData,
            });
    
            const imageData = await imageResponse.json();
    
            if (imageResponse.ok) {
                const metadata = {
                    name: title,
                    description: subject,
                    image: imageData.ipfsUrl,
                    attributes: [
                        { trait_type: 'Campaign Name', value: campaignName },
                        { trait_type: 'URL', value: url },
                        { trait_type: 'Total Ads', value: total },
                    ],
                };
    
                setMessage('Sending mint request to backend...');
    
                // Updated URL to the new server
                const response = await fetch('https://totalconversion.ai/mint', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        totalNFTs: parseInt(total),
                        metadata,
                        userPrivateKey: privateKey,
                        recipients: recipients.slice(0, parseInt(total)), // Send only the number of recipients needed
                    }),
                });
    
                const data = await response.json();
    
                if (response.ok) {
                    setMessage('Minting process completed successfully!');
                } else {
                    setMessage(`Minting failed: ${data.error}`);
                }
            } else {
                setMessage('Failed to upload image to IPFS.');
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('An error occurred while trying to mint NFTs.');
        }
    };
    

    return (
        <div className="form-container">
            <header>ads.token</header>
            <form id="adForm">
                <h1>Campaign Name:</h1>
                <input
                    type="text"
                    name="campaign_name"
                    placeholder="Enter campaign name"
                    value={campaignName}
                    onChange={(e) => setCampaignName(e.target.value)}
                    required
                />

                <label htmlFor="upload_ad_image">Upload Ad Image</label>
                <input
                    type="file"
                    name="upload_ad_image"
                    id="upload_ad_image"
                    accept="image/*"
                    onChange={handleImageUpload}
                    required
                />

                <h3>Title:</h3>
                <input
                    type="text"
                    name="title"
                    placeholder="Enter title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                />

                <h3>Subject:</h3>
                <textarea
                    name="text_body"
                    placeholder="Text Body"
                    rows="5"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                ></textarea>

                <label htmlFor="url">URL</label>
                <input
                    type="url"
                    name="url"
                    placeholder="Enter URL"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    required
                />

                <label htmlFor="num_ads">Number of Ads</label>
                <input
                    type="number"
                    name="num_ads"
                    id="num_ads"
                    min="1"
                    placeholder="Enter number of ads"
                    value={total}
                    onChange={(e) => setTotal(e.target.value)}
                    required
                />

                <label htmlFor="private_key">Private Key</label>
                <input
                    type="password"
                    name="private_key"
                    placeholder="Enter your private key"
                    value={privateKey}
                    onChange={(e) => setPrivateKey(e.target.value)}
                    required
                />

                <button type="button" className="gold-button" onClick={handleMint}>
                    Submit
                </button>

                {message && <p>{message}</p>}
            </form>
        </div>
    );
}

export default MintingForm;
