import React, { useEffect, useState } from 'react';
import { JsonRpcProvider, Contract } from 'ethers';
import CONTRACT_ABI from './BatchMintingToken.json';

const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
const INFURA_URL = process.env.REACT_APP_INFURA_URL;

function NFTDisplay() {
    const [nfts, setNfts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchNFTs = async () => {
            try {
                const provider = new JsonRpcProvider(INFURA_URL);
                const contract = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, provider);

                const items = [];
                let tokenId = 1;
                let moreTokens = true;

                while (moreTokens) {
                    try {
                        console.log(`Fetching tokenId: ${tokenId}`);
                        const tokenUri = await contract.tokenURI(tokenId);
                        console.log(`Fetched tokenURI: ${tokenUri}`);
                        const metadata = await fetch(tokenUri).then((response) => response.json());
                        console.log(`Fetched metadata:`, metadata);
                        items.push({
                            id: tokenId,
                            ...metadata,
                        });
                        tokenId++;
                    } catch (error) {
                        console.log(`Error fetching tokenId: ${tokenId}`, error);
                        moreTokens = false;
                    }
                }

                setNfts(items);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching NFTs:', error);
                setLoading(false);
            }
        };

        fetchNFTs();
    }, []);

    if (loading) {
        return <div>Loading NFTs...</div>;
    }

    return (
        <div style={{ padding: '20px' }}>
            <h2 style={{ fontSize: '2.5rem', fontWeight: 'bold', marginBottom: '20px' }}>All NFTs</h2>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {nfts.map((nft) => (
                    <div key={nft.id} style={{ margin: '10px', border: '1px solid #ddd', padding: '10px', borderRadius: '10px' }}>
                        <h3>{nft.name}</h3>
                        <img src={nft.image} alt={nft.name} style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                        <p>{nft.description}</p>
                        <ul>
                            {nft.attributes.map((attr, index) => (
                                <li key={index}>
                                    {attr.trait_type}: {attr.value}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default NFTDisplay;